<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊福井必備的景點套票，精選福井多個人氣設施與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukui/zh_tw/havefun_title-tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukui/01_TC.jpg'),
          title: ['越前鐵道全線1日無限乘車券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣福井市中央1丁目1-1(越前鐵道福井站櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.echizen-tetudo.co.jp/'] },
                { cid: 2, text: ['可使用路線圖: '],noColor:true },
                { cid: 3, text: ['https://www.echizen-tetudo.co.jp/route/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['產品內容: 一日內可無限次搭乘越前鐵道的三國蘆原線・勝山永平寺線。','<br>※不適用：路線巴士・福井鐵道'] },
                { cid: 2, text: ['使用前請務必先去兌換成全線1日無限乘車券。'] },
            
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hffukui/02_TC.jpg'),
          title: ['福井站前5店鋪使用可能!蕎麥麵美食巡禮♪蕎麥麵周遊優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣福井市中央1-2-1 (Happiring 1F綜合櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.happiring.com/event/detail.php?cd=1409'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可使用店鋪: 蕎麥店 丸八、越前蕎麥店 見吉屋本店、網代蕎麥店 福之井店、福井市觀光物產福福館 福福茶屋、越前蕎麥倶樂部'] },
                { cid: 2, text: ['營業時間請事先至官網確認。','<br>※可能因臨時休業・客滿而無法使用。'] },
                { cid: 3, text: ['請至Happiring 1F綜合櫃台出示二維碼兌換優惠券。'] },
                { cid: 4, text: ['前往選定的店鋪於點餐時出示優惠券。我們將從您的總帳單中抵扣相應張數的優惠券。','※適用餐點因店鋪而異。','※每家店鋪可使用的優惠券數量不同。'] },
                { cid: 5, text: ['一張優惠券含4張面值為300日圓的優惠券。'] },
                { cid: 6, text: ['優惠券(實體券)兌換後半年內有效。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hffukui/03_TC.jpg'),
          title: ['JR福井站前複合大樓「Happiring」館內通用優惠券1000日圓'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣福井市中央1-2-1 (Happiring 1F綜合櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.happiring.com/zh/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日圓優惠券。'] },    
                { cid: 2, text: ['結帳時請在各店鋪出示優惠券。(出示二維碼無法使用。請務必在使用前兌換成優惠券。)'] },    
                { cid: 3, text: ['本券不可兌換現金或找零。'] },    
                { cid: 4, text: ['無法使用於對象店舖之外的地方。(館內不適用優惠券店鋪：彩券、Family Mart、Seiren Planet)'] },    
                { cid: 5, text: ['優惠券(實體券)兌換後半年內有效。'] },    
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hffukui/04_TC.jpg'),
          title: ['丸岡城入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣坂井市丸岡町1-59 (丸岡城售票處)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://enjoy.pref.fukui.lg.jp/tcn/spot/spot-20/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 包含「丸岡城」、「丸岡歴史民俗資料館」、「一筆啟上 日本最短書信館」入場費用'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['無法重複入場。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hffukui/05_TC.jpg'),
          title: ['蘆原溫泉「美松」一日泡湯 (含租借毛巾)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣蘆原市舟津26-10 (1樓櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.mimatu.net/han/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品內容: 溫泉入湯費、浴巾租借費 '] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['僅能入場泡湯一次。太陽殿是男澡堂，明月殿是女澡堂。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hffukui/06_TC.jpg'),
          title: ['越前松島水族館入館券 1500日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣坂井市三國町崎74-2-3(售票口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.echizen-aquarium.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1500日圓優惠券。(僅能折抵入館費)','<br>※不會找零'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['由於颱風、大雪、災害等原因會有臨時休館、提前閉館時間、延遲開館時間的情況，敬請諒解。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hffukui/07_TC.jpg'),
          title: ['恐龍博物館入館券(常設展門票)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '福井縣勝山市村岡町寺尾51-11 (本館綜合櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.dinosaur.pref.fukui.jp/cn/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。'] },
                { cid: 2, text: ['可能因展品更換或設施檢查而臨時休館。'] },
                { cid: 3, text: ['可在票券(入館券)記載的日期內重複入館。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukui&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukui-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>